@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply bg-background min-h-screen antialiased  text-custom-black font-inter;
  }

  html {
    @apply text-[86%];
  }

  @media screen and (min-width: 640px) {
    html {
      @apply text-[90%];
    }
  }

  @media screen and (min-width: 768px) {
    html {
      @apply text-[94%];
    }
  }

  @media screen and (min-width: 1024px) {
    html {
      @apply text-[97%];
    }
  }

  @media screen and (min-width: 1280px) {
    html {
      @apply text-[100%];
    }
  }
}

/* Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

@layer components {
  .markdown ol {
    @apply list-decimal pl-0 list-inside;
  }

  .markdown a {
    @apply underline;
  }

  .markdown b {
    @apply font-bold;
  }

  .msgFormField {
    @apply absolute  top-full mt-[2px] text-[12px] animate-SlideUpFadeIn;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.spinner {
  --spinner-color: #3888c5;

  aspect-ratio: 1/1;
  border-radius: 50%;

  animation-name: spin;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.spinner--quarter {
  width: 48px;
  border: 5px solid var(--spinner-color);
  border-top-color: transparent;

  animation-duration: 1s;
}
